<template>
  <section id="factures_table">
    <TableClassic
      :columns="columns"
      :rows="rows"
      checkboxes
      :active-sort="activeSort"
      @sort="$emit('sort', $event)"
    >
      <template #id-4d="{ value, }">
        {{ value.id_4d }}
      </template>
      <template #num-engagement="{ value, }">
        {{ value.num_engagement || "-" }}
      </template>
      <template #date="{ value, }">
        {{ value.date | moment($t("global.format-date")) }}
      </template>
      <template #total="{ value, }">
        <div>
          <strong
            v-if="value.total_ttc !== value.total_ht"
            class="all-taxes"
            v-html="$t('prix.ttc', { prix: moneyFilter(value.total_ttc), })"
          />
          <span
            class="duty-free"
            v-html="$t('prix.ht', { prix: moneyFilter(value.total_ht), })"
          />
        </div>
      </template>
      <template #actions="{ value, }">
        <ButtonClassic
          variant="special"
          color="primary"
          icon="right"
          :disabled="!value.imprime || !value.url_pdf"
          @click="openPdf(value)"
        >
          <template #right-icon>
            <UilFileCheck />
          </template>
        </ButtonClassic>
        <ButtonPopover
          v-if="['view_commande', 'view_expedition', ].some((perm) => hasPerm(perm))"
          v-model="value._dropdownLinks"
          color="primary"
          variant="special"
          icon="left"
          size="small"
          placement="bottom-right"
          trigger="click"
        >
          <template #left-icon>
            <UilEllipsisV size="12" />
          </template>
          <ul class="text-small">
            <li v-if="hasPerm('view_commande')">
              <router-link
                class="options-list-item-btn"
                :to="{ name: 'commandes_factures_commandes', query: { facture: value.id, }, }"
              >
                {{ $t("commande.commandes-liees") }}
              </router-link>
            </li>
            <li v-if="hasPerm('view_expedition')">
              <router-link
                class="options-list-item-btn"
                :to="{ name: 'commandes_factures_expeditions', query: { facture: value.id, }, }"
              >
                {{ $t("commande.expeditions-liees") }}
              </router-link>
            </li>
          </ul>
        </ButtonPopover>
      </template>
    </TableClassic>
  </section>
</template>

<script>
import {
  ButtonClassic,
  ButtonPopover,
  moneyFilter,
  openInNewTab,
} from "@lde/core_lde_vue";

import TableClassic from "@/components/table/TableClassic.vue";

import { mapGetters } from "vuex";

import { UilFileCheck, UilEllipsisV } from "@iconscout/vue-unicons";

/**
 * Affiche les factures de l'établissement courant.
 */
export default {
  name: "FacturesTable",
  components: {
    ButtonClassic,
    ButtonPopover,
    TableClassic,
    UilFileCheck,
    UilEllipsisV,
  },
  props: {
    /**
     * Données à afficher dans le tableau.
     */
    rows: {
      type: Array,
      required: true,
    },
    /**
     * Clé et sens du tri.
     */
    activeSort: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      columns: [
        {
          key: "id-4d",
          name: this.$t("facture.n-facture"),
          sortKey: "id_4d",
        },
        {
          key: "num-engagement",
          name: this.$t("chorus.n-d-engagement"),
          sortKey: "num_engagement",
        },
        {
          key: "date",
          name: this.$t("facture.date-de-facturation"),
          type: "date",
        },
        {
          key: "total",
          name: this.$t("facture.montant"),
          type: "number",
          sortKey: "total_ttc",
        },
        {
          key: "actions",
          notSortable: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["hasPerm"]),
  },
  methods: {
    openInNewTab,
    moneyFilter,
    /**
     * Ouvre un nouvel onglet avec le PDF de la facture.
     * @param {Object} ligne Infos de la ligne.
     */
    openPdf(ligne) {
      if (ligne.url_pdf) {
        this.openInNewTab(ligne.url_pdf);
      } else {
        this.$toast.error({ title: this.$t("facture.facture-pas-pu-etre-trouvee") });
      }
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/table/factures_table.scss";
</style>
